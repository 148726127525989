#doc>.topnav-container {
  margin-top: 55px;
  border-top: 1px solid var(--border-color);
  transition-property: background-color, color, border-color;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}

#quill-container {
  padding-top: 160px;
  margin: auto;
  padding-left: 2em;  
  padding-right: 2em;
  height: 800px;
}

.ql-container {
  font-size: 16px;
  border-color: var(--border-color) !important;
}

.ql-editor {
  padding: 32px 36px;
}

.ql-toolbar,
.ql-picker-options,
.ql-picker.ql-expanded .ql-picker-label {
  border-color: var(--border-color) !important;
}

.ql-picker {
  color: var(--ql-editor-toolbar) !important;
}

.ql-snow .ql-stroke {
  stroke: var(--ql-editor-toolbar);
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: var(--ql-editor-toolbar);
}
.ql-picker-options {
  background-color: var(--bg-color) !important;
}

.ql-blank::before {
  color: var(--ql-editor-blank) !important;
  left: unset !important;
  right: unset !important;
}

.ql-color > .ql-picker-options,
.ql-background > .ql-picker-options {
  width: 153px !important;
}

.ql-picker.ql-font {
  width: 140px !important;
}

.ql-font-Consolas,
.ql-font span[data-value="Consolas"]::before {
  font-family: Consolas;
}

.ql-font-Monospace,
.ql-font span[data-value="Monospace"]::before {
  font-family: monospace;
}

.ql-font-Times-New-Roman,
.ql-font span[data-value="Times-New-Roman"]::before {
  font-family: 'Times New Roman', Times, serif;
}

.ql-font-Impact,
.ql-font span[data-value="Impact"]::before {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.ql-font-Luminari,
.ql-font span[data-value="Luminari"]::before {
  font-family: Luminari;
}

.ql-font-Cursive,
.ql-font span[data-value="Cursive"]::before {
  font-family: cursive;
}

.ql-font-Bradley-Hand,
.ql-font span[data-value="Bradley-Hand"]::before {
  font-family: Bradley Hand;
}

.ql-font-Brush-Script-MT,
.ql-font span[data-value="Brush-Script-MT"]::before {
  font-family: Brush Script MT;
}

#toolbar,
#editor {
  background: var(--editor-bg-color);
  transition-property: background-color, color, border-color;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}

@media screen and (max-width: 768px) {
  #quill-container {
    padding-left: 0;
    padding-right: 0;
    height: 600px;
  }
}